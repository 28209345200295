import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import React from "react";
import tw, { styled } from "twin.macro";

import Right from "../../static/icons/chevron-right.svg";
import Svg1 from "../../static/images/about-1.svg";
import Svg2 from "../../static/images/about-2.svg";
import Svg3 from "../../static/images/about-3.svg";
import Svg4 from "../../static/images/about-4.svg";
import Process from "../../static/images/triangular-process.png";
import AnimatedSvg from "../components/AnimatedSvg";
import { Container } from "../components/Container";
import { Layout } from "../components/Layout/Layout";
import Slogan from "../components/Slogan";

const Section = styled.section`
  ${tw`md:flex justify-center items-center my-16`}

  p {
    ${tw`text-xl max-w-xl`}
  }

  svg {
    ${tw`fill-current text-turquoise w-32 my-8 mx-auto md:(m-0 mr-8)`}
  }
`;

const About: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Layout
      title={"about"}
      ogTitle={"Triangular-pod - The Process On Demand Platform"}
      author={"Triangular-pod"}
      ogImage={Process}
      ogDescription={
        "We provide access to available production capacities and experts to people that need to use these resources to create new products. We aim for excellent communication with our partners to understand their requirements and find the perfect match between materials, processing systems, and knowledge."
      }
      ogURL={"www.triangular-pod.com/about/"}
    >
      <Container tw="pt-16 pb-32">
        <div tw="max-w-2xl mx-auto text-center">
          <h1 tw="font-black text-4xl">{t("about.header")}</h1>
          <p tw="mt-8 mb-16 text-2xl">{t("about.subheader")}</p>
        </div>

        <Section>
          <AnimatedSvg>
            <Svg1 />
          </AnimatedSvg>
          <p>{t("about.people")}</p>
        </Section>
        <Section>
          <AnimatedSvg>
            <Svg2 />
          </AnimatedSvg>
          <p>{t("about.aim")}</p>
        </Section>
        <Section>
          <AnimatedSvg>
            <Svg3 />
          </AnimatedSvg>
          <p>{t("about.platform")}</p>
        </Section>

        <hr />

        <Section>
          <AnimatedSvg>
            <Svg4 />
          </AnimatedSvg>
          <div>
            <p tw="mb-4">{t("about.connects")}</p>
            <ul>
              <li>
                <Link to="/material-owners" tw="font-black hover:text-turquoise transition">
                  {t("materialOwners.header")} <Right tw="inline fill-current text-grey-light! h-10!" />
                </Link>
              </li>
              <li>
                <Link to="/experts" tw="font-black hover:text-turquoise transition">
                  {t("experts.header")} <Right tw="inline fill-current text-grey-light! h-10!" />
                </Link>
              </li>
              <li>
                <Link to="/equipment-owners" tw="font-black hover:text-turquoise transition">
                  {t("equipmentOwners.header")} <Right tw="inline fill-current text-grey-light! h-10!" />
                </Link>
              </li>
            </ul>
          </div>
        </Section>

        <Slogan />
      </Container>
    </Layout>
  );
};

export default About;
